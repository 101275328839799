import { useState, useEffect, useCallback, memo, useMemo } from "react";
import { fetchImageHistory, getEvalTasks } from "../api";

import { SearchOutlined, InstagramOutlined } from "@ant-design/icons/es";
import { Col, Row, Card, Input, Pagination, Tag, Switch } from "antd/es";

import CardTitle from "./CardTitle";

import '../style/mediabrowser.scss';
import { deepEquals, useInterval } from "../util";
import { Hoverable } from "./Hoverable";
import modelStorage from "../store/model-storage";


const MediaBrowser = (props) => {
    const { onGenerated } = props;

    const [history, setHistory] = useState([]);
    const [totalMatches, setTotalMatches] = useState(0);
    const [query, setQuery] = useState('');
    const [collapsed, setCollapsed] = useState(false);
    const [page, setPage] = useState(1);
    const [evalQueue, setEvalQueue] = useState([]);

    const imageFilter = modelStorage.useImageFilter();
    const setImageFilter = modelStorage.useSetImageFilter();
    const [showEvals, setShowEvals] = useState(false);

    useEffect(() => {
        const listener = ({ imageFilter, evals }) => { setQuery(imageFilter); setShowEvals(evals) };
        window.addEventListener('set-image-filter', listener);
        return () => window.removeEventListener('set-image-filter', listener);
    }, []);

    const updateHistory = useCallback(async () => {
        const q = query.trim();

        let filters = { 'is_eval': showEvals ? true : null };
        if (imageFilter) {
            filters['model.keyword'] = imageFilter;
        }

        const { values, total } = await fetchImageHistory(36, filters, q, (page - 1) * 36);
        setHistory(values || []);
        setTotalMatches(total);
    }, [query, page, imageFilter, showEvals]);

    useInterval(() => updateHistory(), 10000, false, [updateHistory]);

    useInterval(() => {
        getEvalTasks().then(q => setEvalQueue(q.filter(e => !e.result)));
    }, 10000, false, [setEvalQueue]);


    const images = history.filter(h => h?.inference?.images)
        .map(h => ({ ...h.inference, '_timestamp': h['@timestamp'] }))
        .map(e => ({ args: e, url: e.images[0] }));

    const loadArgs = (args) => window.dispatchEvent(new CustomEvent('custom.setArgs', { detail: args }));
    const setBaseImage = (args) => window.dispatchEvent(new CustomEvent('custom.setBaseImage', { detail: args }));
    const setViewing = (args) => window.dispatchEvent(new CustomEvent('custom.setViewing', { detail: args }));

    const selectImage = (args, url) => {
        loadArgs(args);
        setViewing(url);
    };

    useEffect(() => {
        onGenerated(() => () => updateHistory);
    }, [onGenerated, updateHistory]);

    const bodyStyle = { padding: '0 !important' };

    if (collapsed) {
        bodyStyle.display = 'none';
    }

    return (
        <Card bodyStyle={bodyStyle} title={<CardTitle icon={<InstagramOutlined />} collapsed={collapsed} setCollapsed={setCollapsed}>Media Browser <span className="subtitle">{evalQueue.length} eval{evalQueue.length === 1 ? '' : 's'} queued</span></CardTitle>} className="run-container settings-card" style={{ height: '100%' }}>
            <Row>
                <Col span={24}>
                    <Input
                        style={{ marginBottom: 8 }}
                        placeholder="Search..."
                        suffix={<span style={{ fontSize: 12, color: 'var(--text-muted)' }}>{totalMatches} results</span>}
                        value={query}
                        onChange={e => { setQuery(e.target.value); setPage(1); }}
                        addonBefore={<SearchOutlined style={{ padding: '0 8px' }} />}
                        addonAfter={<Switch checked={showEvals} onChange={e => setShowEvals(e)} title="Show evals" />}
                    />

                    {imageFilter && <div className="image-browser-model-filter">
                        <Tag color="blue" closable onClose={() => setImageFilter(null)}>{imageFilter}</Tag>
                    </div>}
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{ textAlign: 'center', marginBottom: 8 }}>
                    <Pagination
                        position="bottom"
                        total={totalMatches}
                        current={page}
                        pageSize={36}
                        onChange={setPage}
                        showQuickJumper={false}
                        showSizeChanger={false}
                        showTotal={false}
                        align="center"
                    />
                </Col>
            </Row>

            <Row gutter={0} >
                <ImageThumbGrid
                    images={images}
                    selectImage={selectImage}
                    setBaseImage={setBaseImage}
                />
            </Row>

            <Row>
                <Col span={24} style={{ textAlign: 'center', marginTop: 8 }}>
                    <Pagination
                        position="bottom"
                        total={totalMatches}
                        current={page}
                        pageSize={36}
                        onChange={setPage}
                        showQuickJumper={false}
                        showSizeChanger={false}
                        showTotal={false}
                        align="center"
                    />
                </Col>
            </Row>
        </Card>
    )
}

const ImageThumbGrid = memo(function ImageThumbGrid(props) {
    const { images, selectImage, setBaseImage } = props;

    return (<>
        {images.map(({ args, url }, idx) => (
            <ImageThumb
                key={args._timestamp}
                args={args}
                image={url}
                onClick={() => selectImage(args, url)}
                setBaseImage={setBaseImage}
                newSeed={idx < images.length - 1 && args.seed !== images[idx + 1].args.seed + 1}
            />
        ))}
    </>)
}, deepEquals);


const SCHEDULER_NAME_MAP = {
    'eulera': 'EA',
    'euler': 'e',
    'dpm3': 'D3',
    'unipc': 'PC',
}

const ImageThumb = memo(function ImageThumb(props) {
    const { image, args, onClick, setBaseImage, newSeed } = props;

    const onContextMenu = useCallback(e => {
        e.preventDefault();
        setBaseImage(image);
        return false;
    }, [image, setBaseImage]);

    let url = image;
    let thumb = image;

    if (args.has_thumbs) {
        url = image.replace('.png', '--hq.webp');
        thumb = image.replace('.png', '--thumb256.webp');
    }

    const scheduler = SCHEDULER_NAME_MAP[args.scheduler] ?? args.scheduler;
    const hoverComponent = useMemo(() => <HoverPreview image={url} args={args} />, [args, url]);

    return (
        <Hoverable component={hoverComponent}>
            <Col xxl={4} xs={6} className={`inference-image-thumb-wrapper ${newSeed ? 'new-seed' : ''}`} onContextMenu={onContextMenu}>
                <img draggable={false} className="inference-image-thumb" src={thumb} width="100%" onClick={onClick} />

                <span className="inference-image-thumb-footer">
                    <span className="top left">{scheduler} {args.steps}</span>
                    <span className="top right">{args.cfg.toFixed(1)}</span>
                    <span className="bottom right tight" style={{ fontFamily: 'monospace' }}>{args.seed.toString(36)}</span>
                </span>
            </Col>
        </Hoverable>
    );
}, deepEquals);

const filtered = ['images', 'num', 'subdir', 'name'];

const HoverPreview = (props) => {
    const { image, args } = props;

    return (<div className="image-preview-container">
        <div className="image-preview-main">
            <img src={image} width={'100%'} style={{ maxWidth: 384, maxHeight: 384 }} />

            <div className="image-preview-footer">
                {args.prompt}
            </div>
        </div>

        <ParameterList args={args} />
    </div>);
}

export const ParameterList = (props) => {
    const { args } = props;

    return (
        <div className="image-preview-params">
            {Object.keys(args).filter(k => !filtered.includes(k)).sort().map(k => (
                <Parameter key={k} name={k} value={args[k]} />
            ))}
        </div>
    )
}

const Parameter = (props) => {
    const { name, value } = props;
    if (name === "loras") {
        return <LoraParameter name={name} value={value} />
    }

    return (
        <div className="param-row">
            <span className="param-name">{name}</span>
            <span className="param-value">{(value ?? "").toString()}</span>
        </div>
    )
}

const LoraParameter = (props) => {
    const { name, value } = props;
    return (
        <div className="param-row">
            <span className="param-name">{name}</span>
            <span className="param-value">{JSON.stringify(value ?? "").toString()}</span>
        </div>
    )
}



export default MediaBrowser;
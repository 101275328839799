import React, { useState, useEffect, useCallback, memo, useMemo } from "react";
import { DownloadOutlined, FireFilled, ReloadOutlined, LockFilled, FireOutlined, SettingFilled, QuestionCircleFilled, DeleteOutlined, HourglassOutlined, EyeOutlined, ExpandOutlined, UnlockFilled, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, Row, Card, InputNumber, Checkbox, Select, Button, Input } from "antd";

import { downloadModel, getLoraPreview, getLoraPreviews, invokeLLM, listModels, listVaeModels, queueImageGeneration, setInferencePriority } from "../api";

import MediaBrowser, { ParameterList } from "./MediaBrowser";
import TinyPreview from "./TinyPreview";
import CardTitle from "./CardTitle";
import ModelStorageBrowser from "./ModelStorageBrowser";
import { Hoverable } from "./Hoverable";
import useModal from "antd/es/modal/useModal";
import { Spin } from "antd";
import { Tag } from "antd";

import store from "../store";
import { ArrowUpOutlined, ColumnHeightOutlined, ColumnWidthOutlined, ExpandAltOutlined, ExperimentFilled, EyeFilled, MessageFilled, MessageOutlined, MinusOutlined, PlusOutlined, StarFilled, UpOutlined, ZoomInOutlined } from "@ant-design/icons/es";

import "../style/inference.scss";
import { Tooltip } from "antd";
import { Alert } from "antd";
import { Space } from "antd";
import { Slider } from "antd";
import { LoraLibrary } from "./LoraLibrary";
import { Image } from "antd";
import { deepEqualsDebugMemo } from "../util";

export const EMPTY_PIXEL = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

const spinoffHistory = [];


function makeControlNetUrl(baseImageUrl, name, threshold1, threshold2) {
    if (name === 'canny' || !name) {
        return `https://training-api.bitgate.ai/controlnet-preview?url=${encodeURIComponent(baseImageUrl)}&type=canny&t1=${threshold1}&t2=${threshold2}`;
    }

    if (name === 'hed') {
        return `https://training-api.bitgate.ai/controlnet-preview?url=${encodeURIComponent(baseImageUrl)}&type=hed`;
    }

    return null;
}

async function hydrateStyleModel(m, argLookup) {
    const path = m.path;
    const timestamp = path.substring(0, path.lastIndexOf('/'));
    const step = Number(path.substring(path.lastIndexOf('/') + 1).replace('checkpoint-', ''));

    let model = {
        path,
        timestamp,
        step,
        args: m.args,
        image_url: null,
    };

    // const resp = await getLoraPreview(timestamp, step);
    // if (resp.length) {
    // image_results.images, run_id
    const data = argLookup[m.args.output_dir]; // resp[0];
    // console.log(m.args.output_dir, data, argLookup);
    if (data?.images?.length) {
        const filename = data.images[0];
        const uuid = data.run_id;

        model.args = m.args; // data.image_results.args;
        model.image_url = `https://public.dreamshaper.io/results/${uuid}/previews/${filename}`;
    }
    // }

    return model;
}

function random(minF, maxF, roundingDigits = 0) {
    let value = Math.random() * (maxF - minF) + minF;
    return Number(value.toFixed(roundingDigits));
}

function mapSizeToHW(size) {
    if (!size) {
        return size;
    }

    const [w, h] = size.split('x');
    return `${h},${w}`;
}

function mapHWToSize(hw) {
    if (!hw) {
        return hw;
    }

    const [h, w] = hw.split(',');
    return `${w}x${h}`;
}

const Inference = ({ derive, queueSize }) => {
    const [prompt, setPrompt] = useState("mushroom");
    const [promptSuggestion, _setPromptSuggestion] = useState(null);
    const [negativePromptSuggestion, setNegativePromptSuggestion] = useState(null);
    const [suggestionRetryAction, _setSuggestionRetryAction] = useState(null);
    const setSuggestionRetryAction = action => _setSuggestionRetryAction(() => action);
    const [promptBusy, setPromptBusy] = useState(false);
    const [negative, setNegative] = useState("bland, blurry");
    const [steps, setSteps] = useState(25);
    const [cfg, setCfg] = useState(7.0);
    const [scheduler, setScheduler] = useState("eulera");
    const [seed, setSeed] = useState((Math.random() * 1000000000) | 0);
    const [cudaGen, setCudaGen] = useState(true);
    const [guidanceRescale, setGuidanceRescale] = useState(0.0);
    const [strength, setStrength] = useState(1.0);
    const [num, setNum] = useState(6);
    const [size, setSize] = useState("1024x1024");

    const [originalSize, setOriginalSize] = useState(null);
    const [targetSize, setTargetSize] = useState(null);
    const [cropsCoordsTopLeft, setCropsCoordsTopLeft] = useState(null);

    const [negativeOriginalSize, setNegativeOriginalSize] = useState(null);
    const [negativeTargetSize, setNegativeTargetSize] = useState(null);
    const [negativeCropsCoordsTopLeft, setNegativeCropsCoordsTopLeft] = useState(null);

    const [schedulerConfig, setSchedulerConfig] = useState({});
    // const [schedulerConfigValid, setSchedulerConfigValid] = useState(true);

    const setPromptSuggestion = s => _setPromptSuggestion(s?.replaceAll("\"", "")?.trim());

    // const setSchedulerConfig = (val) => {
    //     let valid = true;

    //     try {
    //         valid = typeof JSON.parse(val) === 'object';
    //     } catch {
    //         valid = false;
    //     }

    //     setSchedulerConfigValid(valid);
    //     _setSchedulerConfig(val);
    // };

    const changeScheduler = (name) => {
        setScheduler(name);

        if (name === 'dpm3') {
            setSchedulerConfig({
                "algorithm_type": "sde-dpmsolver++",
                "use_lu_lambdas": true,
                "use_karras_sigmas": true,
            });
        } else {
            setSchedulerConfig({});
        }
    };

    const [image, setImage] = useState(null);
    const [viewing, setViewing] = useState(null);
    const [baseImage, setBaseImage] = useState(null);

    const [useCompel, setUseCompel] = useState(false);
    const [useControlNet, setUseControlNet] = useState(false);
    const [controlNetImage, setControlNetImage] = useState(null);
    const [controlnet_scale, setControlnetScale] = useState(0.5);
    const [canny, setCanny] = useState([100, 200]);
    const [controlNetRange, setControlNetRange] = useState([0.0, 1.0]);
    const [controlNetName, setControlNetName] = useState("canny");
    const [controlNetPreview, setControlNetPreview] = useState(true);

    const [useIpAdapter, setUseIpAdapter] = useState(false);
    const [ipAdapterImage, setIpAdapterImage] = useState(null);
    const [ipAdapterScale, setIpAdapterScale] = useState(0.6);

    const [useFreeU, setUseFreeU] = useState(false);
    const [freeUScales, setFreeUScales] = useState({ s1: 1.0, s2: 1.0, b1: 1.0, b2: 1.0 });

    const [seedLocked, setSeedLocked] = useState(true);
    const [modelLocked, setModelLocked] = useState(false);
    const [loraLocked, setLoraLocked] = useState(false);
    const [baseLocked,] = useState(false);

    const [, setActiveTask] = useState(null);
    const [busy, setBusy] = useState(false);

    const models = store.models.useModels();
    const setModels = store.models.useSetModels();
    const setStyleModels = store.models.useSetStyleModels();
    const setDiskUsage = store.models.useSetDiskUsage();

    const vaeModels = store.models.useVaeModels();
    const styleModels = store.models.useStyleModels();
    const setVaeModels = store.models.useSetVaeModels();

    const [loadingModels, setLoadingModels] = useState(false);
    const [model, setModel] = useState('DEFAULT');
    const selectedModels = store.models.useSelection();
    const [activeLoras, setActiveLoras] = useState([]);
    const [showLoraLibrary, setShowLoraLibrary] = useState(true);

    const [generateCallback, setGenerateCallback] = useState(null);
    const [expandScheduler, setExpandScheduler] = useState(false);
    const [lastArgs, setLastArgs] = useState(null);

    const [freeformArgs, _setFreeformArgs] = useState('{}');
    const [freeformArgsValid, setFreeformArgsValid] = useState(true);
    const [showFreeform, setShowFreeform] = useState(false);
    const [disableLoraScaling, setDisableLoraScaling] = useState(false);
    const [singleLoraMode, setSingleLoraMode] = useState(true);

    const setFreeformArgs = (val) => {
        let valid = true;

        try {
            valid = typeof JSON.parse(val) === 'object';
        } catch {
            valid = false;
        }

        setFreeformArgsValid(valid);
        _setFreeformArgs(val);
    };

    const updateModels = useCallback(() => {
        setLoadingModels(true);

        listModels().then(d => {
            setModels(d.models);
            setDiskUsage(d.disk_usage);

            getLoraPreviews(d.styles).then(argLookup => {
                Promise.all(d.styles.map(v => hydrateStyleModel(v, argLookup))).then(styles => {
                    // Build key-value lookup dict
                    let lookup = {};
                    for (const style of styles) {
                        lookup[style.path] = style;
                    }

                    setStyleModels(lookup);
                });
            })
        }).finally(() => setLoadingModels(false));

        listVaeModels().then(setVaeModels);
    }, [setDiskUsage, setModels, setStyleModels, setVaeModels]);

    useEffect(() => {
        updateModels();

        const key = setInterval(() => updateModels(), 10_000);

        return () => clearInterval(key);
    }, [updateModels]);

    const loraMissing = !!activeLoras.find(l => !styleModels.hasOwnProperty(l.path));

    const enqueue = async (entry, selection) => {
        // Check if all selected LoRA models are available
        if (activeLoras.length > 0) {
            for (const lora of activeLoras) {
                if (!styleModels.hasOwnProperty(lora.path)) {
                    downloadModel(lora.path.substr(2) + '/diffusion_pytorch_model.safetensors');
                    //updateModels();
                    alert(`LoRA model ${lora.path} is now downloading...`);
                    return;
                }
            }
        }


        if (!freeformArgsValid) {
            alert("Invalid freeform arguments");
            return;
        }

        const targetModels = selection && selection.length ? selection : [entry.model];
        const targetLoras = activeLoras && activeLoras.length ? activeLoras : [null];

        setBusy(true);

        let baseKey = Date.now();
        let s = entry.seed;

        let jobs = [];
        let prompts = entry.prompt.split('||');

        for (const prompt of prompts) {
            for (const model of targetModels) {
                for (const lora of targetLoras) {
                    s = entry.seed;

                    for (let i = 0; i < num; i++) {
                        const loras = lora !== model ? [lora] : undefined;
                        jobs.push({
                            ...entry,
                            model,
                            loras,
                            prompt,
                            key: (baseKey++).toString(),
                            seed: s,
                        });

                        s = s + 1;//(((s << 1) | (s >> 31)) ^ s) & 0x7fffffff;
                    }
                }
            }
        }

        await queueImageGeneration(jobs);

        setBusy(false);

        if (!seedLocked) {
            setSeed(s);
        }
    };

    const loadArgs = (inf) => {
        let p = inf.prompt;

        if (inf.promptPrefix && p.startsWith(inf.promptPrefix)) {
            p = p.substr(inf.promptPrefix.length);
        }

        setPrompt(p);
        setNegative(inf.negative || "");
        setSteps(inf.steps);
        setCfg(inf.cfg);
        setScheduler(inf.scheduler);
        setSeed(inf.seed);

        if (inf.width && inf.height) {
            setSize(`${inf.width}x${inf.height}`);
        } else {
            setSize("1024x1024");
        }

        setOriginalSize(mapHWToSize(inf.original_size ?? null));
        setTargetSize(mapHWToSize(inf.target_size ?? null));
        setCropsCoordsTopLeft(inf.crops_coords_top_left ?? null);

        setNegativeOriginalSize(mapHWToSize(inf.negative_original_size ?? null));
        setNegativeTargetSize(mapHWToSize(inf.negative_target_size ?? null));
        setNegativeCropsCoordsTopLeft(inf.negative_crops_coords_top_left ?? null);


        if (inf.guidance_rescale !== undefined) {
            setGuidanceRescale(inf.guidance_rescale);
        } else {
            setGuidanceRescale(0.0);
        }

        if (inf.cuda_gen !== undefined) {
            setCudaGen(inf.cuda_gen);
        } else {
            setCudaGen(false);
        }

        if (inf.use_compel !== undefined) {
            setUseCompel(inf.use_compel);
        } else {
            setUseCompel(false);
        }

        if (inf.freeu_scales !== undefined) {
            setFreeUScales(inf.freeu_scales);
            setUseFreeU(true);
        } else {
            setUseFreeU(false);
        }

        if (!baseLocked) {
            if (inf.strength !== undefined) {
                setStrength(inf.strength);
            } else {
                setStrength(1.0);
            }

            if (inf.controlnet_base_image !== undefined) {
                setUseControlNet(true);
                setControlnetScale(inf.controlnet_conditioning_scale);
                setControlNetImage(inf.controlnet_base_image);
                setCanny([inf.canny_threshold_1, inf.canny_threshold_2]);
                setControlNetName(inf.controlnet_name ?? "canny");

                if (inf.base_image_url !== undefined) {
                    setBaseImage(inf.base_image_url);
                } else {
                    setBaseImage(null);
                }

                if (inf.control_guidance_start !== undefined) {
                    setControlNetRange([inf.control_guidance_start, inf.control_guidance_end]);
                } else {
                    setControlNetRange([0.0, 1.0]);
                }
            } else if (inf.base_image_url !== undefined) {
                setBaseImage(inf.base_image_url);
                setUseControlNet(false);
                setControlnetScale(0.5);
                setCanny([100, 200]);
                setControlNetName("canny");
            } else {
                setBaseImage(null);
                setUseControlNet(false);
                setControlnetScale(0.5);
                setCanny([100, 200]);
                setControlNetName("canny");
            }
        }

        if (inf.ip_adapter_image !== undefined) {
            setUseIpAdapter(true);
            setIpAdapterImage(inf.ip_adapter_image);
            setIpAdapterScale(inf.ip_adapter_scale);
        } else {
            setUseIpAdapter(false);
            setIpAdapterImage(null);
            setIpAdapterScale(0.6);
        }

        if (!loraLocked) {
            if (inf.loras !== undefined) {
                let loras = inf.loras;
                let needsRefresh = false;

                // Modify lora scales to be compatible with the new format
                for (let lora of loras) {
                    if (lora.scales === undefined) {
                        const scale = lora.scale;

                        // Is the model missing?
                        if (!styleModels.hasOwnProperty(lora.path)) {
                            needsRefresh = true;
                        }

                        lora.scales = {
                            "unet": {
                                "down": scale,
                                "mid": scale,
                                "up": scale,
                            }
                        };
                    }
                }

                setActiveLoras(loras);

                if (needsRefresh) {
                    updateModels();
                }
            } else {
                setActiveLoras([]);
            }
        }

        if (!modelLocked) {
            setModel(inf.model ?? "DEFAULT");
        }

        if (inf.extra_sched_conf !== undefined) {
            setSchedulerConfig(JSON.parse(inf.extra_sched_conf));
        } else {
            setSchedulerConfig({});
        }
    };

    const controlNetArgs = {};

    if (useControlNet && controlNetImage && controlnet_scale) {
        controlNetArgs.controlnet_base_image = controlNetImage;
        controlNetArgs.controlnet_conditioning_scale = controlnet_scale;
        controlNetArgs.canny_threshold_1 = canny[0];
        controlNetArgs.canny_threshold_2 = canny[1];
        controlNetArgs.controlnet_name = controlNetName;
        controlNetArgs.control_guidance_start = controlNetRange[0];
        controlNetArgs.control_guidance_end = controlNetRange[1];
    };

    const loraArgs = {};
    let captionPrefix = "";
    let captionSuffix = "";

    if (activeLoras.length > 0) {
        let loras = [];

        for (const lora of activeLoras) {
            const loraDetails = styleModels[lora.path];

            if (!loraDetails || !loraDetails.args) {
                console.error("No details found for LORA", lora);
            } else {
                const { caption_prefix, caption_suffix } = loraDetails.args;
                captionPrefix = caption_prefix ?? "";
                captionSuffix = caption_suffix ?? "";
            }

            let alpha = loraDetails?.args?.alpha ?? 1.0;
            let rslora = loraDetails?.args?.use_rslora === true;
            let weightFactor = 1.0;
            const rank = loraDetails?.args?.rank ?? 8;

            // I changed this shit somewhere
            if (alpha > 1.0) {
                //alpha /= rank;
            }

            if (rslora && !disableLoraScaling) {
                const alphaClassic = alpha / rank;
                const rsLora = alpha / Math.sqrt(rank);
                weightFactor = rsLora / alphaClassic;
                // console.log('Alpha classic: %f, RSLora: %f, multiplier: %f', alphaClassic, rsLora, weightFactor, alpha / Math.sqrt(rank), alpha / (rank * rank));
            } else {
                // weightFactor = rank / alpha;
            }

            // scales = {
            //     "text_encoder": 0.5,
            //     "text_encoder_2": 0.5,  # only usable if pipe has a 2nd text encoder
            //     "unet": {
            //         "down": 0.9,  # all transformers in the down-part will use scale 0.9
            //         # "mid"  # in this example "mid" is not given, therefore all transformers in the mid part will use the default scale 1.0
            //         "up": {
            //             "block_0": 0.6,  # all 3 transformers in the 0th block in the up-part will use scale 0.6
            //             "block_1": [0.4, 0.8, 1.0],  # the 3 transformers in the 1st block in the up-part will use scales 0.4, 0.8 and 1.0 respectively
            //         }
            //     }
            // }
            // adapter_weight_scales = { "unet": { "down": 1, "mid": 0, "up": 0} }

            let config = {
                path: lora.path,
                alpha: weightFactor,
            };

            let scales = lora.scales;
            const { down, mid, up } = scales["unet"];
            if (down === mid && mid === up) {
                config.scale = down;
            } else {
                config.scales = scales;
            }

            loras.push(config);
        }

        loraArgs.loras = loras;
    }

    const combinedPrompt = `${captionPrefix}${prompt}${captionSuffix}`;

    let ipAdapterArgs = {};
    if (useIpAdapter && ipAdapterImage) {
        ipAdapterArgs.ip_adapter_image = ipAdapterImage;
        ipAdapterArgs.ip_adapter_scale = ipAdapterScale;
    }

    const currentArgs = {
        model,
        prompt: combinedPrompt,
        promptPrefix: captionPrefix ?? undefined,
        promptSuffix: captionSuffix ?? undefined,
        negative: negative && negative.length ? negative : undefined,
        subdir: "webinf",
        name: "oo",
        high_noise_frac: 1,
        steps,
        cfg,
        num: 1,
        seed,
        scheduler,
        key: Date.now().toString(),
        cuda_gen: cudaGen,
        guidance_rescale: guidanceRescale,
        strength: Math.max(0.0, Math.min(1.0, strength)),
        base_image_url: (baseImage ?? undefined),
        extra_sched_conf: JSON.stringify(schedulerConfig),
        width: size.split('x')[0] | 0,
        height: size.split('x')[1] | 0,
        use_compel: useCompel,
        freeu_scales: useFreeU ? freeUScales : undefined,
        stretch_images: true,
        original_size: mapSizeToHW(originalSize) ?? undefined, // h,w
        target_size: mapSizeToHW(targetSize) ?? undefined, // h,w
        crops_coords_top_left: cropsCoordsTopLeft ?? undefined, // y,x
        negative_original_size: mapSizeToHW(negativeOriginalSize) ?? undefined,
        negative_target_size: mapSizeToHW(negativeTargetSize) ?? undefined,
        negative_crops_coords_top_left: negativeCropsCoordsTopLeft ?? undefined,
        ...ipAdapterArgs,
        ...loraArgs,
        ...controlNetArgs,
        ...(freeformArgsValid ? JSON.parse(freeformArgs) : {}),
    };



    useEffect(() => {
        const setLastResult = (ev) => {
            const { urls, args } = ev.detail;
            setActiveTask(null);

            let url = urls[0];
            if (args.has_thumbs) {
                url = url.replace('.png', '.webp')
            }

            setImage(url);
            setLastArgs({ ...args });

            generateCallback?.();
        };

        const _setArgs = (ev) => loadArgs(ev.detail);
        const _setBaseImage = (ev) => setBaseImage(ev.detail);
        const _setViewing = (ev) => setViewing(ev.detail);

        window.addEventListener('custom.lastResult', setLastResult);
        window.addEventListener('custom.setArgs', _setArgs);
        window.addEventListener('custom.setBaseImage', _setBaseImage);
        window.addEventListener('custom.setViewing', _setViewing);

        return () => {
            window.removeEventListener('custom.lastResult', setLastResult);
            window.removeEventListener('custom.setArgs', _setArgs);
            window.removeEventListener('custom.setBaseImage', _setBaseImage);
            window.removeEventListener('custom.setViewing', _setViewing);
        }
    })


    const modelGroups = useMemo(() => {
        const modelGroups = {};
        for (const model of models) {
            const path = model.split("/");

            modelGroups[path[0]] ??= [];
            modelGroups[path[0]].push({ path: model, label: path.slice(1).filter(p => p !== 'unet').join('/') });
        }
        return modelGroups;
    }, [models]);

    const uploadFile = (e, onUrl) => {
        const files = e.target.files ?? e.dataTransfer.files;
        const reader = new FileReader();

        if (!files.length)
            return;

        reader.onload = () => {
            const blob = new Blob([reader.result]);

            fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/upload-image", {
                method: "POST",
                body: blob,
                headers: {
                    "Content-Type": "application/octet-stream"
                }
            }).then(resp => resp.json())
                .then(data => onUrl('https://public.dreamshaper.io/' + data.key))
                .catch(console.error);
        };

        reader.readAsArrayBuffer(files[0]);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === "Enter") {
                enqueue(currentArgs);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    });

    const [showModal, ModalCtx] = useModal();

    const showAvailableArgs = () => {
        showModal.info({
            title: "Available arguments",
            maskClosable: true,
            content: <pre>{JSON.stringify({
                aesthetic_score: 6.0,
                negative_aesthetic_score: 2.5,
                original_size: "1024,1024",
                target_size: "1024,1024",
                crops_coords_top_left: "0,0",
                negative_target_size: "1024,1024",
                negative_crops_coords_top_left: "0,0",
                negative_original_size: "1024,1024",
                high_noise_frac: 0.0,
                prompt_2: "<empty>"
            }, null, 4)}</pre>
        });
    };

    let enqueueText = `Enqueue`;
    let enqueueIcon = <FireFilled />;

    if (num > 1) {
        enqueueText += ` ${num}x`;
    }

    if (selectedModels && selectedModels.length) {
        enqueueText += ` for ${selectedModels.length} models`;
    }

    if (queueSize > 0) {
        enqueueText += ` (${queueSize})`;
    }

    if (loraMissing) {
        enqueueText = "Download LoRA";
        enqueueIcon = (<DownloadOutlined />);
    }

    const promptKeyDown = (ev) => {
        if (ev.code === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
            enqueue(currentArgs);
            return false;
        }
    }

    const spinoffPrompt = async () => {
        setPromptBusy(true);
        setSuggestionRetryAction(spinoffPrompt);

        // You can add one or more + or - symbols at the end of a word to increase or decrease the influence (or impact) of the prompt on the image generation. For example, apple+++ will make the model generate an image that is 3 times more apple-like.
        // Similarly, apple--- will make the model generate an image that is 3 times less apple-like. If you want to apply the influence to a group of words, you can use parentheses. For example, (fruit basket)+++ will make "fruit basket" 3 times more influential in the image generation, instead of just "basket".

        let userMessage = [];

        if (captionPrefix) {
            userMessage.push(`Prefix: "${captionPrefix}"`);
        }

        userMessage.push(`Prompt: "${prompt}"`);

        if (captionSuffix) {
            userMessage.push(`Suffix: "${captionSuffix}"`);
        }

        userMessage = userMessage.join("\n");

        const spinoffMsg = spinoffHistory.length ? spinoffHistory.join("\n") : "<empty>";

        try {
            const res = await invokeLLM(`You are an image generation prompt editor working for Stable Diffusion and Dall-E, helping to generate game assets.

When the user shows their current prompt, you respond with a spin-off of their prompt, and nothing else than the spin-off. The spin-off should be a new prompt that has a different subject to describe, but still fits the presumed environment or setting of the game asset.
You don't talk, and you simply respond with ONLY the spin-off prompt based on their current one.

When thinking of a new prompt, consider the user's existing prefix and suffix, which can sometimes include style or model-specific keywords that may serve as hints for what the use of the asset is.

Remember to ONLY respond with the new prompt, and no chatter!

The game assets cannot be physically large. This means that the suggested prompt should be of reasonable size. Things like "a forest," "a cityscape," "a cozy tavern" and such are simply too large to fit a single asset.
The asset also must not include "surroundings" - that is, descriptions of the background, the location, or other non-object traits must not be included.

Terminology: 
 - "game object" means physical props that can be placed around a scene
 - "game item" means a physically-held, worn, wielded or carried object/item
 Do not suggest items when the request is for game objects, and similarly do not suggest game objects when the subject is game items.

Previous spin-offs (do not reuse):
${spinoffMsg}`, prompt, 1.0);
            const newPrompt = res.choices[0].message.content.replaceAll("\"", "").replace("Prompt: ", "").replace("Prompt:", "");
            spinoffHistory.push(`"${newPrompt}"`)
            setPromptSuggestion(newPrompt);
        } catch (e) {
            console.error(e);
            alert(e);
        }

        setPromptBusy(false);
    };

    const enhancePrompt = async () => {
        setPromptBusy(true);
        setSuggestionRetryAction(enhancePrompt);

        try {
            const res = await invokeLLM(`You are a helpful image generation prompt guru. 
When the user shows their current prompt, you respond with an expanded/more descriptive version of their prompt, and nothing else than that.

## Examples (do not copy verbatim)
User: "a realistic bear."
Your response: "a realistic fearsome bear, with a thick, shaggy brown coat, sharp claws, and a powerful muscular build."

User: "wasp enemy"
Your response: "a big wasp, suspended in mid-air, with yellow-black striped pattern and a prominent stinger"

User: "water barrel"
Your response: "a wooden barrel with two metallic bands around it, the top is filled with a red liquid substance."

User: "ice raptor"
Your response: "a small blue raptor-like creature with large eyes, standing upright and growling to reveal its large teeth."

User: "sandstone"
Your response: "a small, irregularly shaped pile of sandstone with a rough texture, featuring light brown and beige tones."

User: "chef hat"
Your response: "a white cooking hat with a round top and vertical lines on the sides, often known as a chef's hat."

User: "a bronze arrow."
Your response: "a bronze-tipped arrow with a wooden shaft, adorned with white fletching, positioned diagonally."

User: "ember"
Your response: "a glowing orange ember with small dancing flames, yellow highlights, and flickering edges."

User: "fire relic"
Your response: "a brown, irregularly shaped stone featuring a glowing red symbol, which resembles a square with a diagonal line through it, suggesting a mystical or magical quality."

User: "mushroom teleport scroll"
Your response: "a tan scroll with blue cylindrical ends, featuring a red mushroom icon in the center and a red seal hanging from the bottom, typically associated with a teleportation function to a mushroom island."`,
                `User: "${prompt}"`, 1.0);
            const newPrompt = res.choices[0].message.content;
            setPromptSuggestion(newPrompt);
        } catch (e) {
            console.error(e);
            alert(e);
        }

        setPromptBusy(false);
    };

    const editPrompt = async (question = null) => {
        setPromptBusy(true);
        setSuggestionRetryAction(() => editPrompt(question));

        try {
            const res = await invokeLLM(`
## Requirements
You are an image generation prompt editor working for Stable Diffusion and Dall-E.
When the user shows you their current prompt, you respond with an edited version of their prompt, based on their requested change.
Simply respond with ONLY the edited prompt based on their current one and the requested change.

Only write a new caption and adhere STRICTLY to the user's request.
If the user has NO specific request, you can simply enhance the prompt based on the existing one.
If the user also has no existing prompt, you are invited to let your creativity run wild and create a new prompt from scratch.
Otherwise, ALWAYS respect the request of the user.

## Examples
Prompt: "the carcass of a vulture, with flies swarming above. (gray background)"
User request: "wasp enemy"
Your response: "an evil-looking wasp, suspended in mid-air, with yellow-black striped pattern and a prominent stinger. (gray background)"

Prompt: "a disney-style illustration of a cat wearing a hat and a tuxedo"
User request: "jumpsuit"
Your response: "a disney-style illustration of a jumpsuit"

Prompt: "a realistic bear."
User request: "enhance"
Your response: "a realistic fearsome bear, with a thick, shaggy coat, sharp claws, and a powerful muscular build."
`, question ? `Prompt: "${prompt}"\nUser request: "${question}"` : prompt, 1);
            const newPrompt = res.choices[0].message.content.replaceAll('"', '');
            setPromptSuggestion(newPrompt);
        } catch (e) {
            console.error(e);
            alert(e);
        }

        setPromptBusy(false);
    };

    const negativeFromPrompt = async (question = null) => {
        setPromptBusy(true);

        try {
            const res = await invokeLLM(`
You are a helpful image generation prompt guru. 
When the user shows their current prompt (possibly along with a specific change request), you respond with a "negative" (exclusion) prompt to match their prompt, and nothing else than that.
You don't talk, and you simply respond with ONLY the negative prompt based on their current one.

When thinking of a new prompt, consider the user's current prompt. What would be undesired in the image generation? What should be avoided?
Consider keywords such as "ugly", "blurry", "low quality", "unrecognizable", etc. The strongest negative keywords should be used first, as they will be given the highest priority.

Remember to only respond with the negative caption counterpart, and no chatter! Do not talk to the user. Only write the caption.`, prompt, 1.0);
            const newPrompt = res.choices[0].message.content;
            setNegativePromptSuggestion(newPrompt);
        } catch (e) {
            console.error(e);
            alert(e);
        }

        setPromptBusy(false);
    };

    const reduceToEssentials = async () => {
        setPromptBusy(true);

        try {

            const res = await invokeLLM(`
You are a helpful image generation prompt guru specializing in Stable Diffusion. 
When the user shows their current negative-keyword prompt, you respond with a new version of their prompt that is reduced to just the essential keywords, and nothing else than that.
You don't talk, and you simply respond with ONLY the reduced prompt based on their current one.

Remember to only respond with the new reduced caption, and no chatter! Do not talk to the user. Only write a caption.`, negative, 1.0);
            const newPrompt = res.choices[0].message.content;
            setNegativePromptSuggestion(newPrompt);
        } catch (e) {
            console.error(e);
            alert(e);
        }

        setPromptBusy(false);
    };

    const inferFromBase = async () => {
        setPromptBusy(true);

        try {
            const res = await invokeLLM("You are a helpful image generation prompt assistant. When the user shows an image, you respond with a caption of the shown image, and nothing else than the spin-off. You don't talk, and you simply respond with ONLY the generated image caption based on the image shown.\n\nWhen thinking of a new prompt, consider that the caption will be used in Stable Diffusion image generation, so the goal is to get image results as close as possible to the one shown, using sufficient detail.\n\nRemember to only respond with the suggested detailed caption, and no chatter! Do not wrap the response in quotes either.", "Image:", 1.0, baseImage);
            const newPrompt = res.choices[0].message.content;
            setPromptSuggestion(newPrompt);
        } catch (e) {
            console.error(e);
            alert(e);
        }

        setPromptBusy(false);
    };

    const retrySuggestion = async () => {
        const action = suggestionRetryAction;
        if (action) {
            suggestionRetryAction();
        }
    };

    return (<Card title={<CardTitle icon={<FireOutlined />}>Image Generation</CardTitle>} className="run-container settings-card" style={{ width: '100%' }}>
        <Row gutter={[12, 12]}>
            <Col xxl={showLoraLibrary ? 18 : 24}>
                <form onSubmitCapture={e => { e.preventDefault(); return false; }}>
                    <Row gutter={[6, 6]} justify={"space-evenly"} align={"middle"}>
                        <Col className="base-file-wrapper" flex={'0 1 auto'} style={{ alignSelf: 'stretch' }} onDrop={e => { e.preventDefault(); uploadFile(e, setBaseImage); }} onDragOver={e => e.preventDefault()}>
                            <TinyPreview url={baseImage} remove={() => setBaseImage(null)} onClick={() => setBaseImage(window.prompt('Enter image URL'))}>
                                <label htmlFor="base-image-file" className="file-uploader" />
                                <input id="base-image-file" type="file" name="base-image-file" className="invisible-file-input" onChange={e => uploadFile(e, setBaseImage)} accept="image/png, image/jpeg" />
                            </TinyPreview>
                        </Col>

                        <Col flex={'1 1'} >
                            <Row gutter={[12, 6]} justify={"space-evenly"} align={"middle"} >
                                <Col span={24}>
                                    <div style={{ position: 'relative' }}>
                                        {
                                            !!(captionPrefix || captionSuffix) && <span className="caption-attachments">
                                                <span className="caption-attachment">{captionPrefix}</span>
                                                <span className="caption-attachment">{captionSuffix}</span>
                                                <br />
                                            </span>
                                        }

                                        <Input.TextArea
                                            disabled={promptBusy}
                                            readOnly={busy}
                                            onKeyDown={promptKeyDown}
                                            style={{ resize: 'none', minWidth: 310 }}
                                            rows={2}
                                            resizable="false"
                                            placeholder="Enter prompt"
                                            size="large"
                                            value={prompt}
                                            onChange={e => setPrompt(e.target.value)}
                                        />

                                        <div style={{ position: 'absolute', right: 2, bottom: 0, display: 'flex', flexDirection: 'row', columnGap: 0 }}>
                                            <Tooltip title="Infer from base image">
                                                <Button
                                                    size="small"
                                                    style={{ color: '#ccc', opacity: baseImage && !promptBusy ? 1 : 0.3 }}
                                                    disabled={!baseImage && !promptBusy}
                                                    onClick={() => inferFromBase()}
                                                    type="link"
                                                    icon={<EyeFilled />}
                                                />
                                            </Tooltip>

                                            <Tooltip title="Generate spin-off prompt">
                                                <Button
                                                    size="small"
                                                    style={{ color: '#ccc', opacity: prompt.length && !promptBusy ? 1 : 0.3 }}
                                                    disabled={!prompt.length && !promptBusy}
                                                    onClick={() => spinoffPrompt()}
                                                    type="link"
                                                    icon={<ExperimentFilled />}
                                                />
                                            </Tooltip>

                                            <Tooltip title="Enhance prompt detail">
                                                <Button
                                                    size="small"
                                                    style={{ color: '#ccc', opacity: prompt.length && !promptBusy ? 1 : 0.3 }}
                                                    disabled={!prompt.length && !promptBusy}
                                                    onClick={() => enhancePrompt()}
                                                    type="link"
                                                    icon={<PlusOutlined />}
                                                />
                                            </Tooltip>

                                            <Tooltip title="Generate prompt edit...">
                                                <Button
                                                    size="small"
                                                    style={{ color: '#ccc', opacity: prompt.length && !promptBusy ? 1 : 0.3 }}
                                                    disabled={!prompt.length && !promptBusy}
                                                    onClick={() => editPrompt(window.prompt('Enter enhancement request'))}
                                                    type="link"
                                                    icon={<MessageFilled />}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>

                                {promptSuggestion &&
                                    <Col span={24}>
                                        <Alert
                                            message={promptSuggestion}
                                            type="success"
                                            showIcon
                                            onClose={() => setPromptSuggestion(null)}
                                            icon={<ExperimentFilled />}
                                            // banner
                                            action={(<>
                                                <Button
                                                    onClick={() => { setPrompt(promptSuggestion); setPromptSuggestion(null) }}
                                                    icon={<CheckOutlined />}
                                                    size="small"
                                                    type="text"
                                                />

                                                <Button
                                                    onClick={() => retrySuggestion()}
                                                    icon={<ReloadOutlined />}
                                                    size="small"
                                                    type="text"
                                                />
                                            </>)}
                                            closeIcon={<CloseOutlined />}
                                        />
                                    </Col>
                                }

                                <Col span={24}>
                                    <div style={{ position: 'relative' }}>
                                        <Input placeholder="Negative" size="small" value={negative} onChange={e => setNegative(e.target.value)} />


                                        <div style={{ position: 'absolute', right: 2, bottom: 0, display: 'flex', flexDirection: 'row', columnGap: 0 }}>
                                            <Tooltip title="Reduce to essential keywords only">
                                                <Button
                                                    size="small"
                                                    style={{ color: '#ccc', opacity: prompt.length && !promptBusy ? 1 : 0.3 }}
                                                    disabled={!prompt.length && !promptBusy}
                                                    onClick={() => reduceToEssentials()}
                                                    type="link"
                                                    icon={<MinusOutlined />}
                                                />
                                            </Tooltip>

                                            <Tooltip title="Generate based on prompt">
                                                <Button
                                                    size="small"
                                                    style={{ color: '#ccc', opacity: prompt.length && !promptBusy ? 1 : 0.3 }}
                                                    disabled={!prompt.length && !promptBusy}
                                                    onClick={() => negativeFromPrompt()}
                                                    type="link"
                                                    icon={<ExperimentFilled />}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>

                                {negativePromptSuggestion &&
                                    <Col span={24}>
                                        <Alert
                                            message={negativePromptSuggestion}
                                            type="warning"
                                            showIcon
                                            closable
                                            icon={<ExperimentFilled />}
                                            banner
                                            action={(
                                                <Button
                                                    onClick={() => { setNegative(negativePromptSuggestion); setNegativePromptSuggestion(null) }}
                                                    icon={<ArrowUpOutlined />}
                                                    size="small"
                                                    type="text"
                                                />
                                            )}
                                        />
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={[6, 6]} justify={"space-evenly"} align={"middle"} style={{ marginTop: 6, justifyContent: 'stretch', alignContent: 'stretch', alignItems: 'stretch', justifyItems: 'stretch', padding: 4 }}>
                        <Col lg={3} xs={5}>
                            <div className="inference-prop-label">Steps</div>
                            <InputNumber min={1} max={200} step={5} value={steps} onChange={setSteps} style={{ width: '100%' }} />
                        </Col>

                        <Col lg={3} xs={5}>
                            <div className="inference-prop-label">CFG</div>
                            <InputNumber min={0} max={30} step={0.5} value={cfg} onChange={setCfg} style={{ width: '100%' }} />
                        </Col>

                        <Col lg={3} xs={5}>
                            <div className="inference-prop-label">Rescale</div>
                            <InputNumber min={0} max={10} step={0.1} value={guidanceRescale} onChange={setGuidanceRescale} style={{ width: '100%' }} />
                        </Col>

                        <Col lg={3} xs={8}>
                            <div className="inference-prop-label">Strength</div>
                            <InputNumber min={0} max={1} step={0.05} disabled={!baseImage} value={strength} precision={2} onChange={setStrength} addonAfter={<span>{Math.floor((1.0 - strength) * steps) | 0}&gt;</span>} />
                        </Col>

                        <Col lg={5} xs={12}>
                            <div className="inference-prop-label">Scheduler</div>
                            <Space.Compact style={{ width: '100%' }}>
                                <Select value={scheduler} style={{ minWidth: 150, width: '100%' }} onChange={changeScheduler}>
                                    <Select.Option value={"euler"}>Euler</Select.Option>
                                    <Select.Option value={"eulera"}>Euler A</Select.Option>
                                    <Select.Option value={"unipc"}>UniPC</Select.Option>
                                    <Select.Option value={"eulera_karras"}>Euler A (Karras)</Select.Option>
                                    <Select.Option value={"heun"}>Heun</Select.Option>
                                    <Select.Option value={"ddpm"}>DDPM</Select.Option>
                                    <Select.Option value={"ddim"}>DDIM</Select.Option>
                                    <Select.Option value={"dpm3"}>DPM3</Select.Option>
                                </Select>

                                <Button style={{ verticalAlign: 'baseline' }} type={expandScheduler ? "primary" : "default"} onClick={() => setExpandScheduler(v => !v)} icon={<SettingFilled />} />
                            </Space.Compact>
                        </Col>

                        <Col lg={7} xs={12}>
                            <div className="inference-prop-label">Base UNet model</div>
                            <ModelPicker {...{ modelLocked, setModelLocked, loadingModels, setModel, modelGroups, updateModels, model }} />
                        </Col>
                    </Row>

                    <Row gutter={[6, 6]} align={"middle"} style={{ marginTop: 6, justifyContent: 'flex-end', padding: 4 }}>
                        <Col flex="0 0 auto">
                            <Checkbox checked={useControlNet} onChange={e => setUseControlNet(e.target.checked)}>ControlNet</Checkbox>
                        </Col>

                        <Col flex="0 0 auto">
                            <Checkbox checked={useCompel} onChange={e => setUseCompel(e.target.checked)}>Compel</Checkbox>
                        </Col>

                        <Col flex="0 0 auto">
                            <Checkbox checked={useFreeU} onChange={e => setUseFreeU(e.target.checked)}>FreeU</Checkbox>
                        </Col>

                        <Col flex="0 0 auto">
                            <Checkbox checked={useIpAdapter} onChange={e => setUseIpAdapter(e.target.checked)}>IP Adapter</Checkbox>
                        </Col>

                        <Col flex="0 0 auto">
                            <Checkbox checked={showFreeform} onChange={e => setShowFreeform(e.target.checked)}>Freeform</Checkbox>
                        </Col>


                        <Col flex="0 0 auto" xs={6}>
                            #:  <InputNumber min={1} max={100} step={1} value={num} onChange={setNum} />
                        </Col>

                        <SizePicker size={size} setSize={setSize} />

                        <Col flex="0 0 210px">
                            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>{ /* (Math.random() * 1000000000) | 0 */}
                                Seed:  <Input
                                    value={seed}
                                    onChange={e => setSeed(e.target.value | 0)}
                                    width={100}
                                    addonAfter={<Button
                                        icon={<ReloadOutlined />}
                                        onClick={() => setSeed((Math.random() * 1000000000) | 0)} />}
                                    addonBefore={<LockButton locked={seedLocked} setLocked={setSeedLocked} />}
                                />
                            </div>
                        </Col>

                        <Col flex="0 1 auto">
                            <Button loading={busy} disabled={promptBusy || promptSuggestion} htmlType="submit" type="primary" onClick={() => enqueue(currentArgs, selectedModels)} icon={enqueueIcon}>
                                {enqueueText}
                            </Button>
                        </Col>
                    </Row>

                    <Row gutter={[12, 6]} justify={"space-evenly"} align={"middle"} style={{ marginTop: 6 }}>
                        {useControlNet && <Col xxl={12} md={24}>
                            <Card title="ControlNet" size="small" bodyStyle={{ background: '#262a33' }} style={{ background: '#0e1015', border: '1px solid #343d4a' }}>
                                <Row gutter={[6, 6]} align="middle" justify={"center"}>
                                    <Col className="base-file-wrapper" flex={'0 1 auto'} style={{ alignSelf: 'stretch' }} onDrop={e => { e.preventDefault(); uploadFile(e, setControlNetImage); }} onDragOver={e => e.preventDefault()}>
                                        <TinyPreview url={controlNetPreview && controlNetImage ? makeControlNetUrl(controlNetImage, controlNetName, canny[0], canny[1]) : controlNetImage} remove={() => setControlNetImage(null)} onClick={() => setControlNetImage(window.prompt('Enter image URL'))}>
                                            <label htmlFor="base-image-file3" className="file-uploader" />
                                            <input id="base-image-file3" type="file" name="base-image-file3" className="invisible-file-input" onChange={e => uploadFile(e, setControlNetImage)} accept="image/png, image/jpeg" />
                                        </TinyPreview>
                                    </Col>
                                    <Checkbox checked={controlNetPreview} onChange={e => setControlNetPreview(e.target.checked)}>Preview</Checkbox>

                                    <Button onClick={() => setControlNetImage(baseImage ?? null)}>Copy base</Button>
                                    <Button onClick={() => setControlNetImage(ipAdapterImage ?? null)}>Copy IP Adapter</Button>
                                </Row>

                                <Row gutter={[6, 6]} align="middle" justify={"center"}>
                                    <Col flex="0 0 auto">
                                        Scale:{' '}
                                        <InputNumber min={0.1} max={1.0} step={0.05} value={controlnet_scale} onChange={setControlnetScale} />
                                    </Col>

                                    <Col flex="1 0 auto">
                                        Range: {(controlNetRange[0] * steps) | 0} - {(controlNetRange[1] * steps) | 0}

                                        <Slider
                                            range={{ draggableTrack: true }}
                                            min={0.0}
                                            max={1.0}
                                            step={0.01}
                                            value={controlNetRange}
                                            onChange={setControlNetRange}
                                            tooltip={{ formatter: v => <b>{(steps * v) | 0}</b> }}
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={[6, 6]} align="middle" justify={"center"}>
                                    <Col flex="0 0 auto">
                                        <Select value={controlNetName ?? 'canny'} style={{ minWidth: 130 }} onChange={setControlNetName}>
                                            <Select.Option value={"canny"}>Canny</Select.Option>
                                            <Select.Option value={"hed"}>HED</Select.Option>
                                        </Select>
                                    </Col>

                                    <Col flex="0 0 auto">
                                        Canny:{' '}
                                        <InputNumber min={0} max={5000} step={10} value={canny[0]} onChange={v => setCanny([v, canny[1]])} />
                                        <InputNumber min={0} max={5000} step={10} value={canny[1]} onChange={v => setCanny([canny[0], v])} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>}

                        {useIpAdapter && <Col xxl={12} md={24}>
                            <Card title="IP Adapter" size="small" bodyStyle={{ background: '#262a33' }} style={{ background: '#0e1015', border: '1px solid #343d4a' }}>
                                <Row gutter={[6, 6]} align="middle" justify={"center"}>

                                    <Col className="base-file-wrapper" flex={'0 1 auto'} style={{ alignSelf: 'stretch' }} onDrop={e => { e.preventDefault(); uploadFile(e, setIpAdapterImage); }} onDragOver={e => e.preventDefault()}>
                                        <TinyPreview url={ipAdapterImage} remove={() => setIpAdapterImage(null)} onClick={() => setIpAdapterImage(window.prompt('Enter image URL'))}>
                                            <label htmlFor="base-image-file2" className="file-uploader" />
                                            <input id="base-image-file2" type="file" name="base-image-file2" className="invisible-file-input" onChange={e => uploadFile(e, setIpAdapterImage)} accept="image/png, image/jpeg" />
                                        </TinyPreview>
                                    </Col>

                                    <Col flex="1 0 auto">
                                        <Row gutter={[6, 6]} wrap={false} align="stretch" justify={"space-evenly"}>
                                            <Button block onClick={() => setIpAdapterImage(baseImage ?? null)}>Copy from base</Button>
                                            <Button block onClick={() => setIpAdapterImage(controlNetImage ?? null)}>Copy from ControlNet</Button>
                                        </Row>

                                        <Row gutter={[6, 6]} wrap={false} align="middle" justify={"center"}>
                                            <Button block onClick={() => setBaseImage(ipAdapterImage)} disabled={!ipAdapterImage}>Set as base</Button>
                                            <Button block onClick={() => setControlNetImage(controlNetImage ?? null)} disabled={!ipAdapterImage}>Set as ControlNet</Button>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row gutter={[6, 6]} align="middle" justify={"center"}>
                                    <Col flex="0 0 auto">
                                        Scale:{' '}
                                        <InputNumber min={0.0} max={1.0} step={0.05} value={ipAdapterScale} onChange={setIpAdapterScale} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>}


                        {useFreeU && <Col xxl={12} md={24}>
                            <Card title="FreeU" size="small" bodyStyle={{ background: '#262a33' }} style={{ background: '#0e1015', border: '1px solid #343d4a' }}>
                                <Row gutter={[6, 6]} align="middle" justify={"center"}>
                                    <Col flex="0 0 auto">
                                        <InputNumber prefix={<b>S1</b>} min={0.0} max={1.0} step={0.05} value={freeUScales.s1} onChange={v => setFreeUScales(s => ({ ...s, s1: v }))} />
                                        <InputNumber prefix={<b>S2</b>} min={0.0} max={1.0} step={0.05} value={freeUScales.s2} onChange={v => setFreeUScales(s => ({ ...s, s2: v }))} />
                                        <InputNumber prefix={<b>B1</b>} min={1.0} max={1.2} step={0.05} value={freeUScales.b1} onChange={v => setFreeUScales(s => ({ ...s, b1: v }))} />
                                        <InputNumber prefix={<b>B2</b>} min={1.0} max={1.6} step={0.05} value={freeUScales.b2} onChange={v => setFreeUScales(s => ({ ...s, b2: v }))} />

                                        {/* When trying additional parameters, consider the following ranges: 
                                                            b1: 1 ≤ b1 ≤ 1.2
                                                            b2: 1.2 ≤ b2 ≤ 1.6
                                                            s1: s1 ≤ 1
                                                            s2: s2 ≤ 1 */}
                                    </Col>

                                    <Col flex="1 0 auto">
                                        <Button onClick={() => setFreeUScales({ s1: 1.0, s2: 1.0, b1: 1.0, b2: 1.0 })}>Reset</Button>
                                        <Button onClick={() => setFreeUScales({ s1: random(0.0, 1.0, 2), s2: random(0.0, 1.0, 2), b1: random(1.0, 1.2, 2), b2: random(1.2, 1.6, 2) })}>
                                            Randomize
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>}

                        {expandScheduler && <Col xxl={12} md={24}>
                            <Card title="Scheduler config" size="small" bodyStyle={{ background: '#262a33' }} style={{ background: '#0e1015', border: '1px solid #343d4a' }}>
                                <Row gutter={[16, 16]} align="middle" justify={"center"}>
                                    {/* "Euler_K": (EulerDiscreteScheduler, {"use_karras_sigmas": True}),

                                                "DPMPP_2M": (DPMSolverMultistepScheduler, {}),
                                                "DPMPP_2M_K": (DPMSolverMultistepScheduler, {"use_karras_sigmas": True}),
                                                "DPMPP_2M_Lu": (DPMSolverMultistepScheduler, {"use_lu_lambdas": True}),
                                                "DPMPP_2M_Stable": (DPMSolverMultistepScheduler, {"euler_at_final": True}),

                                                "DPMPP_2M_SDE": (DPMSolverMultistepScheduler, {"algorithm_type": "sde-dpmsolver++"}),
                                                "DPMPP_2M_SDE_K": (DPMSolverMultistepScheduler, {"use_karras_sigmas": True, "algorithm_type": "sde-dpmsolver++"}),
                                                "DPMPP_2M_SDE_Lu": (DPMSolverMultistepScheduler, {"use_lu_lambdas": True, "algorithm_type": "sde-dpmsolver++"}),
                                                "DPMPP_2M_SDE_Stable": (DPMSolverMultistepScheduler, {"algorithm_type": "sde-dpmsolver++", "euler_at_final": True}), */}

                                    <Col flex="0 0 auto">
                                        Spacing:{' '}
                                        <Select value={schedulerConfig.timestep_spacing ?? 'linspace'} style={{ minWidth: 130 }} onChange={s => setSchedulerConfig(v => ({ ...v, timestep_spacing: s === 'linspace' ? undefined : s }))}>
                                            <Select.Option value={"leading"}>Leading</Select.Option>
                                            <Select.Option value={"linspace"}>Linspace (default)</Select.Option>
                                            <Select.Option value={"trailing"}>Trailing</Select.Option>
                                        </Select>
                                    </Col>

                                    <Col flex="0 0 auto">
                                        Algorithm:{' '}
                                        <Select disabled={scheduler !== 'dpm3'} value={schedulerConfig.algorithm_type ?? 'none'} style={{ minWidth: 130 }} onChange={s => setSchedulerConfig(v => ({ ...v, algorithm_type: s === 'none' ? undefined : s }))}>
                                            <Select.Option value={"none"}>Default</Select.Option>
                                            <Select.Option value={"sde-dpmsolver++"}>SDE</Select.Option>
                                        </Select>
                                    </Col>


                                    <Col flex="0 0 auto">
                                        <Checkbox checked={schedulerConfig.use_karras_sigmas} onChange={e => setSchedulerConfig(v => ({ ...v, use_karras_sigmas: e.target.checked ? true : undefined }))}>Karras sigmas</Checkbox>
                                    </Col>

                                    <Col flex="0 0 auto">
                                        <Checkbox checked={schedulerConfig.use_lu_lambdas} onChange={e => setSchedulerConfig(v => ({ ...v, use_lu_lambdas: e.target.checked ? true : undefined }))}>Lu Lambdas</Checkbox>
                                    </Col>

                                    <Col flex="0 0 auto">
                                        <Checkbox checked={schedulerConfig.euler_at_final} onChange={e => setSchedulerConfig(v => ({ ...v, euler_at_final: e.target.checked ? true : undefined }))}>Euler at Final</Checkbox>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        }
                    </Row>

                    {showFreeform && <Row gutter={[12, 6]} justify={"space-evenly"} align={"middle"} style={{ marginTop: 6 }}>
                        <Col flex="1 1 auto">
                            Freeform arguments: <Button size="small" icon={<QuestionCircleFilled />} onClick={showAvailableArgs} /><br />
                            <Input.TextArea
                                className={freeformArgsValid ? '' : 'input-error'}
                                style={{ fontFamily: 'monospace' }}
                                value={freeformArgs}
                                onChange={e => setFreeformArgs(e.target.value)}
                                rows={5}
                                placeholder="Freeform arguments" />
                        </Col>

                        <Col xxl={12} md={24}>
                            {/* Contains things like originalSize, targetSize, crop coords, etc */}
                            <Card title="Freeform conditioning parameters" size="small" bodyStyle={{ background: '#262a33' }} style={{ background: '#0e1015', border: '1px solid #343d4a' }}>
                                <Row gutter={[4, 4]} align="middle" justify={"center"}>
                                    <Col flex="1">
                                        Original size:{' '}
                                        <SizePicker size={originalSize} setSize={setOriginalSize} allowEmpty />
                                    </Col>

                                    <Col flex="1">
                                        Target size:{' '}
                                        <SizePicker size={targetSize} setSize={setTargetSize} allowEmpty />
                                    </Col>

                                    <Col flex="1">
                                        Crop coords:{' '}
                                        <Input placeholder="y,x" value={cropsCoordsTopLeft} onChange={e => setCropsCoordsTopLeft(e.target.value ?? null)} />
                                    </Col>

                                    <Col flex="1">
                                        Negative original:{' '}
                                        <SizePicker size={negativeOriginalSize} setSize={setNegativeOriginalSize} allowEmpty />
                                    </Col>

                                    <Col flex="1">
                                        Negative target:{' '}
                                        <SizePicker size={negativeTargetSize} setSize={setNegativeTargetSize} allowEmpty />
                                    </Col>

                                    <Col flex="1">
                                        Negative crop coords:{' '}
                                        <Input placeholder="y,x" value={negativeCropsCoordsTopLeft} onChange={e => setNegativeCropsCoordsTopLeft(e.target.value ?? null)} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>}

                    <Row gutter={[12, 12]} style={{ marginTop: 6, padding: 6, flexWrap: 'nowrap' }}>
                        <Col xxl={12} xl={12} style={{ background: '#0e1015', border: '1px solid #343d4a', flexShrink: 1, marginRight: 2, padding: 4 }}>
                            {image ? <div className="output-preview-container">
                                <div className="output-preview">
                                    <Image src={image} width="512" style={{ width: '100%', maxWidth: '100%', height: 'auto' }} preview={{ movable: false, src: image }} />
                                </div>

                                <div className="output-preview-footer">
                                    <Button onClick={() => setBaseImage(image)} size="small">Set as base</Button>
                                    <Button style={{ float: 'right' }} onClick={() => setImage(null)} size="small">Close</Button>
                                </div>
                            </div> :
                                <img src={EMPTY_PIXEL} width="512" style={{ maxWidth: '100%', height: 'auto' }} />}
                        </Col>

                        <Col xxl={12} xl={12} style={{ background: '#0e1015', border: '1px solid #343d4a', flexShrink: 1, marginLeft: 2, padding: 4 }}>
                            <GalleryImageViewer viewing={viewing} setBaseImage={setBaseImage} setViewing={setViewing} />
                        </Col>
                    </Row>
                </form>
            </Col>

            {showLoraLibrary && <Col xxl={6}>
                <Checkbox checked={disableLoraScaling} onChange={e => setDisableLoraScaling(e.target.checked)}>Disable scaling</Checkbox>
                <Checkbox checked={singleLoraMode} onChange={e => setSingleLoraMode(e.target.checked)}>Single-model mode</Checkbox>

                <LoraLibrary {...{ activeLoras, setActiveLoras, styleModels, locked: loraLocked, setLocked: setLoraLocked, singleLoraMode }} />
            </Col>}
        </Row>

        {ModalCtx}
    </Card>

    )
}

const DEFAULT_QUANT_STATE = {
    enabled: false,
    colors: 16,
    nofs: true,
    width: 64,
    height: 64,
    resize_last: true,
    rescale: true,
};

const GalleryImageViewer = (props) => {
    const { setBaseImage, setViewing } = props;
    let { viewing } = props;

    const [quantConfig, setQuantConfig] = useState(DEFAULT_QUANT_STATE);

    if (!viewing) {
        return <img src={EMPTY_PIXEL} width="512" style={{ maxWidth: '100%', height: 'auto' }} />
    }

    const { enabled, ...quantParams } = quantConfig;
    if (enabled) {
        const url = encodeURIComponent(viewing);
        const params = [`image=${url}`];

        for (const k in quantParams) {
            if (quantParams[k]) {
                params.push(`${k}=${encodeURIComponent(quantParams[k])}`);
            }
        }

        const concat = params.join('&');
        viewing = `https://training-api.bitgate.ai/pngquant?${concat}`;
    }

    return (<div className="output-preview-container">
        <div className="output-preview">
            <Image
                src={viewing}
                width="512"
                style={{
                    width: '100%',
                    maxWidth: '100%',
                    height: 'auto'
                }}
                preview={{ movable: false, src: viewing }}
            />

            <div className="output-preview-footer">
                <Button onClick={() => setBaseImage(viewing)} size="small">Set as base</Button>

                <label>
                    <Checkbox checked={quantConfig.enabled} onChange={ev => setQuantConfig(c => ({ ...c, enabled: ev.target.checked }))} /> Quant
                </label>

                {quantConfig.enabled && <>
                    <InputNumber value={quantConfig.colors} onChange={v => setQuantConfig(c => ({ ...c, colors: v }))} style={{ width: 56 }} />

                    <label>
                        <Checkbox checked={quantConfig.resize_last} onChange={ev => setQuantConfig(c => ({ ...c, resize_last: ev.target.checked }))} /> PS
                    </label>

                    <label>
                        <Checkbox checked={!quantConfig.rescale} onChange={ev => setQuantConfig(c => ({ ...c, rescale: !ev.target.checked }))} /> Small
                    </label>
                </>}

                <Button style={{ float: 'right' }} onClick={() => setViewing(null)} size="small">Close</Button>
            </div>
        </div>
    </div>);
}

// EulerDiscreteScheduler {
//     "_class_name": "EulerDiscreteScheduler",
//     "_diffusers_version": "0.28.0",
//     "beta_end": 0.012,
//     "beta_schedule": "scaled_linear",
//     "beta_start": 0.00085,
//     "clip_sample": false,
//     "final_sigmas_type": "zero",
//     "interpolation_type": "linear",
//     "num_train_timesteps": 1000,
//     "prediction_type": "epsilon",
//     "rescale_betas_zero_snr": false,
//     "sample_max_value": 1.0,
//     "set_alpha_to_one": false,
//     "sigma_max": null,
//     "sigma_min": null,
//     "skip_prk_steps": true,
//     "steps_offset": 1,
//     "timestep_spacing": "leading",
//     "timestep_type": "discrete",
//     "trained_betas": null,
//     "use_karras_sigmas": false
//   }

// FrozenDict([('num_train_timesteps', 1000), ('beta_start', 0.00085), ('beta_end', 0.012), 
// ('beta_schedule', 'scaled_linear'), ('trained_betas', None), ('prediction_type', 'epsilon'), 
// ('interpolation_type', 'linear'), ('use_karras_sigmas', False), ('sigma_min', None), 
// ('sigma_max', None), ('timestep_spacing', 'leading'), ('timestep_type', 'discrete'), 
// ('steps_offset', 1), ('rescale_betas_zero_snr', False), ('final_sigmas_type', 'zero'), 
// ('_use_default_values', ['rescale_betas_zero_snr', 'timestep_type', 'final_sigmas_type', 'sigma_max', 'sigma_min']), 
// ('_class_name', 'EulerDiscreteScheduler'), ('_diffusers_version', '0.28.0'), ('clip_sample', False), 
// ('sample_max_value', 1.0), ('set_alpha_to_one', False), ('skip_prk_steps', True)])

export const defaultLoraScales = (scale = 1.0) => ({ "unet": { "down": scale, "mid": scale, "up": scale } });

const isUniform = (scales) => scales["unet"].down === scales["unet"].mid && scales["unet"].mid === scales["unet"].up;

export const modifyScale = (scales, value, key) => {
    const newScales = { ...scales["unet"] };
    newScales[key] = value;
    return { "unet": newScales };
}

export const loraPathComparator = (a, b) => {
    const aParts = a.path.split('/');
    const bParts = b.path.split('/');

    for (let i = 0; i < Math.min(aParts.length, bParts.length) - 1; i++) {
        if (aParts[i] !== bParts[i]) {
            return bParts[i].localeCompare(aParts[i]);
        }
    }

    // Last parts are numbers, we parse them and compare
    const aStep = parseInt(aParts[aParts.length - 1].split("-")[1], 10);
    const bStep = parseInt(bParts[bParts.length - 1].split("-")[1], 10);

    return bStep - aStep;
}

const ModelPicker = (props) => {
    const { modelLocked, setModelLocked, loadingModels, setModel, modelGroups, updateModels, model } = props;

    const selectedModels = store.models.useSelection();
    const value = selectedModels.length ? selectedModels.map(m => ({ value: m, label: `${selectedModels.length} selected` })) : { value: model, label: model };

    return (<Space.Compact style={{ display: 'flex', width: '100%' }}>
        <LockButton locked={modelLocked} setLocked={setModelLocked} />

        <Select labelInValue value={value} listHeight={512} popupMatchSelectWidth={false} showSearch loading={loadingModels} style={{ minWidth: 2, flex: '1 1' }} onChange={e => setModel(e.value)}>
            <Select.OptGroup label="Base models" key="Base models">
                <Select.Option value="DEFAULT">DEFAULT</Select.Option>
            </Select.OptGroup>

            {Object.entries(modelGroups).map(([label, group]) => (
                <Select.OptGroup label={label} key={label}>
                    {group.map(m => (
                        <Select.Option value={m.path} key={m.path}>{m.path}</Select.Option>
                    ))}
                </Select.OptGroup>
            ))}
        </Select>

        <Button
            onClick={updateModels}
            loading={loadingModels}
            type={"default"}
            icon={<ReloadOutlined />} />
    </Space.Compact>)
}

const consuming = (handler) => {
    return (ev) => {
        handler(ev);
        ev.preventDefault();
        ev.stopPropagation();
        return false;
    }
}

export const LockButton = (props) => {
    const { locked, setLocked, ...rest } = props;

    return (
        <Button
            type={locked ? "primary" : "default"}
            onClick={consuming(() => setLocked(!locked))}
            icon={locked ? <LockFilled /> : <UnlockFilled />}
            {...rest}
        />
    )
}

const LANDSCAPE_SIZES = [
    "1216x832",
    "1152x832",
];

const PORTRAIT_SIZES = [
    "832x1216",
    "832x1152",
];

const SQUARE_SIZES = [
    "1024x1024",
];

const ALL_SIZES = [
    "512x2048",
    "512x1984",
    "512x1920",
    "512x1856",
    "576x1792",
    "576x1728",
    "576x1664",
    "640x1600",
    "640x1536",
    "704x1472",
    "704x1408",
    "704x1344",
    "768x1344",
    "768x1280",
    "832x1216",
    "832x1152",
    "896x1152",
    "896x1088",
    "960x1088",
    "960x1024",
    "1024x1024",
    "1024x960",
    "1088x960",
    "1088x896",
    "1152x896",
    "1152x832",
    "1216x832",
    "1280x768",
    "1344x768",
    "1408x704",
    "1472x704",
    "1536x640",
    "1600x640",
    "1664x576",
    "1728x576",
    "1792x576",
    "1856x512",
    "1920x512",
    "1984x512",
    "2048x512",
];

const STARRED = [
    "1216x832",
    "832x1216",
];

const getAspectRatio = (size) => {
    const [w, h] = size.split("x").map(Number);
    return w / h;
}

/**
 * Returns the human-friendly aspect ratio of a given size, such as the form photography uses.
 * @param {*} size 
 */
const getHumanAR = (size) => {
    const [w, h] = size.split("x").map(Number);
    const gcd = (a, b) => b === 0 ? a : gcd(b, a % b);
    const ratio = gcd(w, h);
    return `${w / ratio}:${h / ratio}`;
}

const SizePicker = (props) => {
    const { size, setSize, allowEmpty = false } = props;

    return (
        <Col flex="0 0 auto">
            <Space.Compact>
                <Select value={size} style={{ minWidth: 140 }} onChange={setSize} popupMatchSelectWidth={false}>
                    {allowEmpty && <Select.Option value={null}>(unset)</Select.Option>}

                    <Select.OptGroup label="Default">
                        <Select.Option value="1024x1024">
                            1024x1024
                        </Select.Option>
                    </Select.OptGroup>

                    <Select.OptGroup label={<>Landscape <ColumnWidthOutlined /></>}>
                        {LANDSCAPE_SIZES.map(s => <Select.Option value={s} key={s}>
                            {s} ({getHumanAR(s)})
                            {STARRED.includes(s) && <StarFilled style={{ opacity: 0.25, marginLeft: 8 }} />}
                        </Select.Option>)}
                    </Select.OptGroup>

                    <Select.OptGroup label={<>Portrait <ColumnHeightOutlined /></>}>
                        {PORTRAIT_SIZES.map(s => <Select.Option value={s} key={s}>
                            {s} ({getHumanAR(s)})
                            {STARRED.includes(s) && <StarFilled style={{ opacity: 0.25, marginLeft: 8 }} />}
                        </Select.Option>)}
                    </Select.OptGroup>

                    <Select.OptGroup label="All">
                        {ALL_SIZES.map(s => <Select.Option value={s} key={"_" + s}>
                            {s} ({getHumanAR(s)})
                            {STARRED.includes(s) && <StarFilled style={{ opacity: 0.25, marginLeft: 8 }} />}
                        </Select.Option>)}
                    </Select.OptGroup>
                </Select>
            </Space.Compact>
        </Col>
    )
}


export default Inference;

import { memo, useState, useCallback, useEffect } from "react";
import { Tag, Card, Button, Spin, Row, Col } from "antd/es";
import { deepEqualsDebugMemo } from "../util";
import CardTitle from "./CardTitle";
import { DeleteOutlined, HourglassOutlined, UpOutlined } from "@ant-design/icons";
import { Hoverable } from "./Hoverable";
import { setInferencePriority, truncateInferenceQueue } from "../api";


export const InferenceQueueCard = memo(({ setQueueSize }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [queue, setQueue] = useState([]);
    const [lastKey, setLastKey] = useState(null);


    const updateQueue = useCallback(() => {
        fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/inference?h=" + Math.random())
            .then(resp => resp.json())
            .then(tasks => tasks.map(task => ({ ...task, data: JSON.parse(task.data) })))
            .then(tasks => {
                tasks.sort((a, b) => b.key - a.key);

                const completed = tasks.filter(t => !!t.result);

                if (completed.length && completed[0].key !== lastKey) {
                    if (lastKey !== null) {
                        window.dispatchEvent(new CustomEvent('custom.lastResult', { detail: { urls: completed[0].result, args: completed[0].data } }));
                    }

                    setLastKey(completed[0].key);
                }

                const q = tasks.filter(t => !t.result);
                q.sort((a, b) => a.key - b.key);

                setQueue(q);
                setQueueSize(q.length);
            });
    }, [setQueueSize, lastKey]);

    const nukeQueue = () => {
        if (window.confirm("Are you sure you want to delete the entire queue?")) {
            truncateInferenceQueue().then(() => updateQueue());
        }
    };

    useEffect(() => {
        const interval = setInterval(() => updateQueue(), 2000);
        updateQueue();

        return () => clearInterval(interval);
    }, [updateQueue]);

    const queueLenTag = (<Tag color="processing" style={{ marginLeft: 4, padding: '0px 6px', fontSize: 12 }}>{queue.length}</Tag>);

    return (
        <Card bodyStyle={collapsed ? { display: 'none' } : { maxHeight: 220, overflowY: 'auto' }}
            title={<CardTitle
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                icon={<HourglassOutlined />}
            >Inference Queue {queueLenTag}{' '}
                {queue.length > 0 && <Button size="small" type="danger" onClick={nukeQueue} icon={<DeleteOutlined />} />}
            </CardTitle>}
            className="run-container settings-card inf-queue-card" style={{ width: '100%' }}
        >
            <Row gutter={[12, 12]}>
                <Col xxl={24} className="inf-queue">
                    {collapsed ? null : queue.map(task => <QueueItem key={task.key} task={task} updateQueue={updateQueue} />)}
                </Col>
            </Row>
        </Card>)
}, deepEqualsDebugMemo("InferenceQueueCard"));

const QueueItem = memo(function QueueItem(props) {
    const { task, updateQueue } = props;
    const { processing, key, priority = 1 } = task;
    const { steps, cfg, prompt } = task.data;

    const [deleting, setDeleting] = useState(false);

    const deleteItem = useCallback(() => {
        setDeleting(true);
        fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/inference/" + task.key, { method: "DELETE" })
            .then(() => updateQueue()).finally(() => setDeleting(false));
    }, [updateQueue, task.key]);

    const setPriority = (newPriority) => {
        setInferencePriority(key, newPriority);
    };

    const onClick = () => {
        window.dispatchEvent(new CustomEvent('custom.setArgs', { detail: task.data }))
    }

    return (<Hoverable>
        <div className="inf-queue-item">
            <div className="inf-queue-item-prefix">
                {processing ? <Spin spinning={false} /> : <Button size="small" loading={deleting} type="outline" icon={<DeleteOutlined />} onClick={deleteItem} />}
            </div>

            <Tag color="processing" className="inf-queue-steps">{steps}</Tag>
            <Tag color="processing" className="inf-queue-cfg">{cfg}</Tag>

            <div className="inf-queue-prompt" onClick={onClick}>{prompt}</div>

            <div className="inf-queue-actions">
                <Button size="small" type="primary" icon={<UpOutlined />} onClick={() => setPriority(priority + 1)}>{priority !== 1 ? ` ${priority}` : null}</Button>
            </div>
        </div>
    </Hoverable >)
}, deepEqualsDebugMemo("QueueItem"));

